//import "%modules%/header/header";
//import "%modules%/section-new-offers/section-new-offers";
//import "%modules%/footer/footer";
$(function () {
    var NewOffersSliderParam = {
      //	lazyLoad: 'ondemand',
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: "#new-offers-next",
      prevArrow: "#new-offers-prev",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
          }
        },
        {
          breakpoint: 640,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
        }
      ]
    }
    $("#new-offers-carousel").slick(NewOffersSliderParam)

    NewOffersSliderParam.nextArrow = "#popular-offers-next"
    NewOffersSliderParam.prevArrow = "#popular-offers-prev"      
    $("#popular-offers-carousel").slick(NewOffersSliderParam)

});

$(function(){
   // $.fancybox.defaults.hash = false;
	$('input[name="customer_phone"]').mask('+7(999)999-99-99')
})

//product-slider
$(function(){

  $("#product-images-carousel").slick({
    autoplay: true,
    speed: 1000,
    arrows: false,
    asNavFor: "#product-images-nav-carousel"
  });

  $("#product-images-nav-carousel").slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      vertical:true,
      focusOnSelect: true,
      centerMode:true,
      asNavFor: "#product-images-carousel",
      nextArrow: "#product-images-nav-carousel__next",
      prevArrow: "#product-images-nav-carousel__prev",
  })

  $("#slider").slick({
    autoplay: true,
    speed: 1000,
    arrows: false,
    asNavFor: "#thumbnail_slider"
  });
  $("#thumbnail_slider").slick({
    slidesToShow: 3,
    speed: 1000,    
    asNavFor: "#slider"
  });

})